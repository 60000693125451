import React from 'react';
import Header2 from './../Common/Header3';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import WebBanner from './../Elements/ContactUs/WebBanner';
import WebAbout from './../Elements/ContactUs/WebAbout';
import { Helmet } from 'react-helmet';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
             <Helmet>
        <title>Connect with Shadow infosystem: Reach Out Today!</title>
        <meta name="description" content="Contact Shadow infosystem for innovative IT solutions. Reach us for expert consultations, support, and inquiries. Your success begins here." />
        <meta name="keywords" content="Top IT Company in Noida" />
      </Helmet>
                <Header2 />
                <div className="page-content">
                    <WebBanner/>
                    <WebAbout/>
                </div>
                 <Footer2 />
            </>
        );
    };
};

export default Service1;