import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">
                    Why Choose an E-Commerce Marketing Agency?
                  </h3>
                  <p>Incorporating services of an e-commerce marketing agency such as Shadow infosystem will definitely improve the business. Even though you do possess the required skills to manage your business, online marketing entails unique knowledge and skills. By partnering with an agency that understands the intricacies of e-commerce, you gain access to</p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-1.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/business-plan.png")}
                          alt=""
                        />
                        <h4 className="post-title">Tailored strategies</h4>
                      </div>
                      <div className="">
                        <p>We do not subscribe to the idea that there can be a single prescription that would work for everyone. As we manage your account, we create strategies that apply to your industry and the audience that you are targeting.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/customer-service.png")}
                          alt=""
                        />
                        <h4 className="post-title">Expertise in multiple channels</h4>
                      </div>
                      <div className="">
                        <p>We have all the necessary tools and knowledge that will allow us to solve any aspect of your site’s promotion from search engine optimization to paid advertising.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-4.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/management.png")}
                          alt=""
                        />
                        <h4 className="post-title">Time and resource savings</h4>
                      </div>
                      <div className="">
                        <p>Thus, outsourced e-commerce marketing means that you concentrate on managing your business while we deal with the specifics of marketing.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <p>We take the time to develop fairly smooth and effective marketing strategies that are positive to a business. Our skilled team who have been working in the e-commerce sector for several years will help you to build the best strategy for effective marketing with reasonable ROI.</p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
        
      </>
    );
  }
}

export default Blog2;
