import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/bacgarref.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 fscs1213098">
          <div className="container">
            {/* TITLE START */}
            <div className="">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 mb-3">Our Services</h2>
                  <p>We provide innovative IT solutions to help businesses grow<br/>secure data, and maximize their online presence.</p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img className="technology-img home-tech" src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")} alt=""/>
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")} alt=""/>
                        <h4 className="post-title">Website Design & Development</h4>
                      </div>
                      <div className="">
                        <p>{" "}First impressions hold significant importance, and we
                          aim to swiftly secure that agreement for you. In our
                          role as your website design agency, we operate in a
                          consultative approach, working as your advisors,
                          designers, and developers{" "}</p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/website-development"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img home-tech"
                      src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                      alt=""
                    />
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                          alt=""
                        />
                        <h4 className="post-title">E-Commerce Solutions</h4>
                      </div>
                      <div className="">
                        <p>
                          Our comprehensive skills in hosting, search engine
                          marketing, design, analytics, and conversion
                          optimization are unified to provide e-commerce
                          solutions. These solutions are geared towards
                          sustaining month-to-month growth in your sales,
                          ensuring a continuous upward trajectory.
                        </p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/e-Commerce-website-development"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img home-tech"
                      src={require("./../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png")}
                      alt=""
                    />
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png")}
                          alt=""
                        />
                        <h4 className="post-title">Digital Marketing</h4>
                      </div>
                      <div className="">
                        <p>
                          Shadow infosystem stands as a leading digital
                          marketing and SEO services provider in India. Through
                          the entire process, from planning to final execution,
                          we diligently implement comprehensive digital
                          marketing strategies for our clientele.
                        </p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/social-media-optimization"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img home-tech"
                      src={require("./../../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")}
                      alt=""
                    />
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Search Engine Optimization
                        </h4>
                      </div>
                      <div className="">
                        <p>
                          Covering a spectrum from SEO Audit to On-page
                          Optimization to Link Building, Shadow infosystem, a
                          leading SEO Agency, delivers top-notch SEO services.
                          If your goal is securing and maintaining a top
                          position in Google Search results, we offer the ideal
                          solutions.
                        </p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/search-engine-optimization"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img home-tech"
                      src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                      alt=""
                    />
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">Informatica</h4>
                      </div>
                      <div className="">
                        <p>
                          We provide advanced Informatica Services, seamlessly
                          integrating and optimizing your data management. Trust
                          our expert team for efficient solutions that enhance
                          business intelligence, ensuring your success in the
                          dynamic, data-driven landscape.
                        </p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/informatica"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img home-tech"
                      src={require("./../../../images/locationpagesimages/cloud_server_digital_transformation_security.png")}
                      alt=""
                    />
                    <div className="sx-post-info rtrtrtqw bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/cloud_server_digital_transformation_security.png")}
                          alt=""
                        />
                        <h4 className="post-title">Cyber Security</h4>
                      </div>
                      <div className="">
                        <p>
                          Our robust Cyber Security Services, leverage
                          cutting-edge solutions to safeguard digital assets.
                          Our expert team ensures data confidentiality and
                          integrity, offering comprehensive security measures.
                          Trust us for comprehensive security measures,
                          mitigating risks, and empowering your business.
                        </p>
                      </div>
                      <div className="clearfix mnbgfdtr">
                        <div className="common-enq-bot1">
                         <button class="darksoul-hover-fill-button2" aria-label="View More" type="button">
                         <div class="color-fill-2 new_one_animation5"></div>
                         <NavLink to={"/cyber-secuirity"} rel="bookmark" className="">View More{" "}<svg xmlns="http://www.w3.org/2000/svg" width="20"
                         height="20" viewBox="0 0 32 32">
                         <path fill="currentColor" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"/>
                         </svg>
                         </NavLink>
                         </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
