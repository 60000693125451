import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import videoBg from "./../../../images/logisoft.mp4";

var bgimg1 = require("./../../../images/1374.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t8023 back-ground-color ertyuioiu-dfdfdf" style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
       {/* <div className="about_image_flms1123">
          <img className="about_image_flms1" src={require("./../../../images/16339534_v910-aew-069.jpg")} alt=""/>
        </div>*/}
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                   {/* <div className="col-xl-5 col-lg-5 col-md-5">
                    <img className="" src={require("./../../../images/102.jpg")} alt=""/>
                    </div>*/}
                    <div className="col-xl-12 col-lg-12 col-md-12 offset-lg-0">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="text-center white-text">
                            <h3 className="wfgrbwe ert678 mb-3 heascss">
                              Key Features
                            </h3>
                            <p className="sdfgdvd">We are provides a single-window view of operational excellence across locations facilitating planning, execution, monitoring and control of freight movements. It helps manage revenue flows, streamlines documentation and meets regulatory requirements. LogiSoft tracks job status and enhances the customer satisfaction by providing real-time updates.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
