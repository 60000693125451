import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const logos = [
  {
    videoURL: "https://www.youtube.com/embed/JT05eaxlEjo?si=dC8BBIKHk9vwGQh1",
  },
  {
    videoURL: "https://www.youtube.com/embed/ooTdN7cH-Wc?si=fRL2tsndKM0sqgj-",
  },
  {
    videoURL: "https://www.youtube.com/embed/LvSUUofi538?si=015oEzRu1rkHDBv_",
  },
  {
    videoURL: "https://www.youtube.com/embed/crcifaRLERo?si=PNXdMDsyDnyrZvKA",
  },
  {
    videoURL: "https://www.youtube.com/embed/5ioMzTLN0-A?si=G_2KxJZ-ZV1uhrc3",
  },
  {
    videoURL: "https://www.youtube.com/embed/ge18llcgiPI?si=On5LMSnuPmvBDdoe",
  },
  {
    videoURL: "https://www.youtube.com/embed/tZZ0rjEF-24?si=P95Hqhc6tZ09BTOJ",
  },
  {
    videoURL: "https://www.youtube.com/embed/eWr8w2AFLts?si=qCu2J9MQnE85LLt_",
  },
];

class ClientsLogo1 extends React.Component {
  render() {
    const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container m-b40">
            {/* TITLE START */}
            <div className="section-head">
              <div
                className={`${this.props.separatoralignment} sx-separator-outer `}
              >
                <div className="text-center">
                  <h3 className="wfgrbwe ert678 mb-3">What Our Clients Say!</h3>
                  <h6>The Voice of Experience – Real People, Real Reviews</h6>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="client-grid">
                <div className="row justify-content-center">
                  <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {logos.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <iframe
                        className="video-frame"
                        title="Making your vision"
                        width="100%"
                        height={120}
                        src={item.videoURL}
                      />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
                </div>
              </div>
            </div>
            <button class="darksoul-hover-fill-button2 sdfhgjmhjhmgnhfgdfsdgfhbd" type="button">
              <div class="color-fill-2"></div>
              <a
                href="https://www.youtube.com/playlist?list=PLDkX--C13HsPwuk2jr9g7F65OhoxejmTh"
                target="_blank"
                className=""
              >
                <span>
                  View Testimonials{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                    />
                  </svg>
                </span>
              </a>
            </button>
            
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
