import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
       <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
        <div className="container">
        <div className="section-content sfsfcsc13232">
        <div className="sx-separator-outer">
                          <div className="text-center">
                            <h1 className="ert678">
                              We Provides
                            </h1>
                            <p>It’s a cloud base ERP road transport supply chain management system which is integrated with financial accounting system and provides complete integration with Documentation, Fleet Management & Finance.</p>
                          </div>
                        </div>
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                        <img src={require('./../../../images/logicis/delivery_10816676.png')} alt="" />  
                        
                    </div>
                    <h4>Cloud Based Freight Forwarding</h4>
                    <p>Cloud based freight forwarding is undoubtedly a much better solution than legacy freight management systems. It is the need of the hour, as it is accessible from any place and time.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/worldwide-shipping.png')} alt="" />  
                    
                    </div>
                      <h4>Cloud Based Transport Management System</h4>
                      <p>Integrated transport management system offers a myriad of functions that ensures end-to-end transparency and control of the whole logistics process.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/air-cargo.png')} alt="" />  
                    
                    </div>
                       <h4>Courier International & Domestic</h4>
                       <p>With an experienced and dynamic team of professionals and a large network throughout the world, we offer a range of international and domestic courier services.</p>
                    </div>
                </div>            
            </div>
        </div>
    </div>
    
    
</div>

      </>
    );
  }
}

export default Blog2;
