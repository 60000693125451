import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const clientele = [
  {
    id: 1,
    image: require("./../../../images/client/new/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },
  {
    id: 2,
    image: require("./../../../images/client/new/sara.jpg"),
    items: ["Canada"],
  },
  {
    id: 3,
    image: require("./../../../images/client/new/seashore_pipes.jpg"),
    items: ["Qatar"],
  },
  {
    id: 4,
    image: require("./../../../images/client/new/indian_army.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 5,
    image: require("./../../../images/client/new/drdo.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 6,
    image: require("./../../../images/client/new/imr.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 7,
    image: require("./../../../images/client/new/cenjows.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 8,
    image: require("./../../../images/client/new/dipm.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 9,
    image: require("./../../../images/client/new/gctc.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 10,
    image: require("./../../../images/client/new/ssr.jpg"),
    items: ["USA"],
  },
  {
    id: 11,
    image: require("./../../../images/client/new/dunex.jpg"),
    items: ["IND", "USA", "UK", "MENA"],
  },
  {
    id: 12,
    image: require("./../../../images/client/new/sonali_ahuja.jpg"),
    items: ["USA"],
  },
  {
    id: 13,
    image: require("./../../../images/client/new/santosh.jpg"),
    items: ["IND"],
  },
  {
    id: 14,
    image: require("./../../../images/client/new/ozone.jpg"),
    items: ["IND"],
  },
  {
    id: 15,
    image: require("./../../../images/client/new/gold_gym.jpg"),
    items: ["IND"],
  },
  {
    id: 16,
    image: require("./../../../images/client/new/hqids.jpg"),
    items: ["IND"],
  },
  {
    id: 17,
    image: require("./../../../images/client/new/afowo.jpg"),
    items: ["IND"],
  },
  {
    id: 18,
    image: require("./../../../images/client/new/om_my_grill.jpg"),
    items: ["USA"],
  },
  {
    id: 19,
    image: require("./../../../images/client/new/jklaxmi.jpg"),
    items: ["IND"],
  },
  {
    id: 20,
    image: require("./../../../images/client/new/shavs.jpg"),
    items: ["USA"],
  },
  {
    id: 21,
    image: require("./../../../images/client/new/bamboo_garden.jpg"),
    items: ["USA"],
  },
  {
    id: 22,
    image: require("./../../../images/client/new/andosa.jpg"),
    items: ["IND", "NEP"],
  },
  {
    id: 23,
    image: require("./../../../images/client/new/fiwe.jpg"),
    items: ["Sweden"],
  },
  {
    id: 24,
    image: require("./../../../images/client/new/aparna.jpg"),
    items: ["IND", "UAE"],
  },
];

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Projects4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      center: false,
      items: 3,
      margin: 40,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 15,
        },
        240: {
          items: 2,
          margin: 15,
        },
        440: {
          items: 3,
          margin: 15,
        },
        600: {
          items: 4,
          margin: 20,
        },
        800: {
          items: 5,
          margin: 20,
        },
        1000: {
          items: 6,
          margin: 20,
        },
        1200: {
          items: 7,
          margin: 20,
        },
      },
    };
    return (
      <>
        <div className="section-full inner-page-padding p-b50 p-t80 ">
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="sx-separator-outer separator-center">
                    <div className="ertyui">
                      <h2 className="ert678 mb__15">
                       Loved By 1500+ Customers All Over The World
                      </h2>
                      <h6 className="mb__0">
                        Our greatest asset is our customers and clients
                        satisfaction!
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="work-carousel-outer mb__30">
                  <div className="row">
                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>

                <div class="client-logo-pic1">
                  <div className="text-center common-enq-bot">
                    <button class="darksoul-hover-fill-button2 sdfhgjmhjhmgnhfgdfsdgfhbd" type="button">
                      <div class="color-fill-2"></div>
                      <div class="color-fill-2"></div>
                    <NavLink to="/clientele" className="">
                      <span>
                        View More Clients{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          />
                        </svg>
                      </span>
                    </NavLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Projects4;
