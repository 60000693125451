import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
<div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
    <div className="container-fluid">
        <div className="section-content">
            <div className="container">
                <div className="qpqq">
                    <div className="row ffeefefeeeecece">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                          <div className="box-freght">
                             <h4>Entry Modules Details</h4>
                             <p>Booking and Job Creation Sea/Air Import</p>
                             <p>Booking and Job Creation Sea/Air Export</p>
                             <p>Job Creation Import Clearance</p>
                             <p>Job Creation Export Clearance</p>
                             <p>Quotation Creation which is linked with Invoice</p>
                             <p>Billing With foreign currencies which is auto convert to INR</p>
                             <p>Auto GST calculation in Billing either registered or unregistered party</p>
                             <p>Purchase Booking (Airline/Shipping Line/Transporter and so on)</p>
                             <p>FAC Provision, Operational Expenses against the Job</p>
                             <p>Sale, Purchase, FAC Provision, Operational Expenses, Debit Note, Credit Note linked with Job</p>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                          <div className="box-freght">
                             <h4>General Reports & Outputs</h4>
                             <p>Job Register</p>
                             <p>Booking Register</p>
                             <p>Job Profitability</p>
                             <p>Shipment Status Report</p>
                             <p>Job Follow-up Report</p>
                             <p>DSR</p>
                             <p>Freight Certificate</p>
                             <p>Delivery Order</p>
                             <p>BL Status Register</p>
                             <p>Covering Letter</p>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                          <div className="box-freght">
                             <h4>Entry Modules Details</h4>
                             <p>Business Volume & Profit/Loss Report on Job Wise</p>
                             <p>Client Wise and Sales Person Wise</p>
                             <p>Module Wise Turnover Report</p>
                             <p>Comparison Sheets of Sales & Purchase Charges</p>
                             <p>Monthly Sales and so on</p>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                          <div className="box-freght">
                             <h4>Entry Modules Details</h4>
                             <p></p>
                             <p></p>
                             <p></p>
                             <p></p>
                             <p></p>
                             <p></p>
                             <p></p>
                             <p></p>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
   
            </>
        );
    }
};

export default About3;