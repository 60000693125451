import React from "react";
import { NavLink } from "react-router-dom";
import TypeDesign from "./../ProductInquery";

var bgimg1 = require("./../../../images/banner/806.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1 ertyuioiu-dfdfdf"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-12">
                <div className="section-head mnbv123 text-center">
                  <div className="sx-separator-outer">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        Freight Logistics Management System
                      </h3>
                      <p>
                        LogiSoft is a Web Based end-to-end solution exclusively designed for the Logistics company. It is modular, scalable and enterprise-wide ‘Logistic suite’ for managing the business of international Freight Forwarders NVOCCs and 3PL companies effectively.</p>
                    </div>
                  </div>
                  <TypeDesign />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
