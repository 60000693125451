import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="text-center">
                            <h1 className="ert678">
                              E-Commerce Marketing Services to <br/>Boost Your Online Business
                            </h1>
                            <p>In today’s competitive digital world, a strong online presence is essential. Shadow infosystem’s e-commerce marketing services are the perfect solution to boost traffic, improve conversion rates, and drive the growth of your online store. Whether launching a new e-commerce site or optimizing an existing marketplace, we offer customized strategies tailored to your goals.</p>
                            <p>Our expert services focus on SEO-driven traffic, conversion optimization, and enhancing user engagement, ensuring measurable results. Let Shadow infosystem help you unlock the full potential of your e-commerce venture and achieve sustainable growth in the digital landscape.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color vdfbdv`}>
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/11435046_4706264.jpg")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              What Are E-Commerce Marketing Services?
                            </h1>
                            <p>E-commerce marketing services refer to a set of activities, tactics, and methods of promoting an online store and its products. Such services may encompass SEO, paid search, email marketing, social media advertising, content marketing, and many others. The objective is to have a well-built online platform that targets the right audience, guides them through the marketing process, and finally, leads to the purchase of the product.</p>
                            <p>At Shadow infosystem, we mainly focus on creating individual promotional solutions exclusive for businesses that not only bring traffic to a business site but also convert them into consumers. Using data-driven strategies, we guarantee that every marketing decision made is relevant to your business goals.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
