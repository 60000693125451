import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import EWebBanner from "./../Elements/ECommerceMarketingServices/EWebBanner";
import EWebAbout from "./../Elements/ECommerceMarketingServices/EWebAbout";
import EMAgency from "./../Elements/ECommerceMarketingServices/EMAgency";
import ERank from "./../Elements/ECommerceMarketingServices/ERank";
import EBenefites from "./../Elements/ECommerceMarketingServices/EBenefites";
import WebCustomPlan from "./../Elements/ecommerceDevelopment/WebCustomPlan";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials";

import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
       <Helmet>
          <title>E-Commerce Marketing Services | Boost Sales with Expert SEO</title>
          <meta name="description" content="Grow your online store with top-notch e-commerce marketing services. Our expert e-commerce SEO agency helps you rank higher and drive more sales. Contact us today!" />
          <meta name="keywords" content="E-Commerce Marketing Services | Boost Sales with Expert SEO" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/ecommerce-marketing-services" />
     </Helmet>
     <Header2 />
        <div className="page-content">
         <EWebBanner/>
         <EWebAbout/>
         <EMAgency />
         <CommenEnquiry />
         <ERank />
         <EBenefites/>
         <WebCustomPlan/>
         <Testimonials/>
       </div>
    <Footer2 />
      </>
    );
  }
}

export default Service1;
