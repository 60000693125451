import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 hg-123">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 heading">
                    Benefits of E-Commerce Marketing Services
                  </h2>
                  <p>The right e-commerce marketing services offer a wide range of benefits to your business, including</p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="seo-work-include" src={require("./../../../images/bar.png")} alt=""/>
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img className="" src={require("./../../../images/bar.png")} alt=""/>
                        <h4 className="post-title">Increased traffic</h4>
                      </div>
                      <div className="">
                        <p>By attracting more visitors to your site through SEO, social media, and paid ads, there will be more prospects for actual sales.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="seo-work-include" src={require("./../../../images/percentage.png")} alt=""/>
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img className="" src={require("./../../../images/percentage.png")} alt=""/>
                        <h4 className="post-title">Better conversion rates</h4>
                      </div>
                      <div className="">
                        <p>Our tactics are aimed at enhancing user experience and making the purchase funnel as easy as possible to guarantee the growth of visitors into customers.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="seo-work-include" src={require("./../../../images/cost-per-click.png")} alt=""/>
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img className="" src={require("./../../../images/cost-per-click.png")} alt=""/>
                        <h4 className="post-title">Cost-effective advertising</h4>
                      </div>
                      <div className="">
                        <p>Our paid marketing will enable you to maximize your advertising budget and extend your reach beyond what is seemingly affordable</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 offset-lg-2">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="seo-work-include" src={require("./../../../images/marketing.png")} alt=""/>
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img className="" src={require("./../../../images/marketing.png")} alt=""/>
                        <h4 className="post-title">Brand awareness</h4>
                      </div>
                      <div className="">
                        <p>Continuous marketing communication across different channels creates awareness of the brand in the market.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="seo-work-include" src={require("./../../../images/analysis.png")} alt=""/>
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img className="" src={require("./../../../images/analysis.png")} alt=""/>
                        <h4 className="post-title">Data-driven results</h4>
                      </div>
                      <div className="">
                        <p>We review your marketing performance regularly, modifying your processes to help your campaigns achieve the highest levels of effectiveness.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  
                  <p>The e-commerce SEO services that we offer at Shadow infosystem as the best e-commerce SEO company are unique and personalized to help your business expand and thrive in today’s online marketplace. Our team of professionals will help you transform your e-commerce store by implementing effective solutions and approaches.</p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
