import React, { useState } from "react";

const WhatsAppForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    coname: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendToWhatsApp = (e) => {
    e.preventDefault();

    const { name, coname, email, phone, message } = formData;

    if (!name || !email || !phone || !message) {
      setStatus("Please fill in all fields before sending.");
      return;
    }

    const whatsappNumber = "918285560008"; // Replace with your WhatsApp number
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      `*Hello*, my name is *${name}*.\n*Company/Organization*: ${coname}\n*Email*: ${email}\n*Phone*: ${phone}\n*Message*: ${message}`
    )}`;

    window.open(whatsappURL, "_blank");
    setStatus("Message sent successfully!");
  };

  return (
    <div className="5uigfh ssvdvnlpo ndjnjiew" style={styles.container}>
      <h4 className="ert678 fef098765">Elevate Your Business Journey</h4>
      <p>If you have a new project that you wish to discuss or would like to visit our office, please provide us with your details.</p>
      <form onSubmit={sendToWhatsApp} style={styles.form}>
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          style={styles.input}
        />
        <input
          type="text"
          name="coname"
          placeholder="Company/Organization"
          value={formData.coname}
          onChange={handleChange}
          style={styles.input}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          style={styles.input}
        />
        <input
          type="tel"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone}
          onChange={handleChange}
          style={styles.input}
        />
        <textarea
          name="message"
          placeholder="Please Describe Your Requirement"
          value={formData.message}
          onChange={handleChange}
          style={styles.textarea}
        ></textarea>
         <div className="dIB zcwf_privacy_txt ssszoho">I agree to the{" "}
         <a href="privacy-policy">{" "}<b>Privacy Policy</b></a>{" "}and{" "}
         <a href="terms-and-conditions"><b>Terms of Service.</b></a>
         </div>
        <button type="submit" style={styles.button}>Send to WhatsApp</button>
      </form>
      {status && <p style={styles.status}>{status}</p>}
    </div>
  );
};

// Basic inline styles for simplicity
const styles = {
  container: { maxWidth: "400px", margin: "auto", textAlign: "left" },
  form: { display: "flex", flexDirection: "column", gap: "10px" },
  input: { padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc" },
  textarea: { padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", height: "80px" },
  button: { padding: "10px", fontSize: "16px", backgroundColor: "#226b6d", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer" },
  status: { marginTop: "10px", fontWeight: "bold", color: "#333" }
};

export default WhatsAppForm;
