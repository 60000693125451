import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const logos = [
  {
    videoURL: "https://www.youtube.com/embed/ERaS2i1KU-U?si=-pQsFMj4nOfBg7Qs",
  },
  {
    videoURL: "https://www.youtube.com/embed/8Ld0UbBMY_8?si=uXDlri0cE_NgBtYV",
  },
  {
    videoURL: "https://www.youtube.com/embed/L4U6JrXcIiM?si=pOWVOfVxFfrhd0E3",
  },
  {
    videoURL: "https://www.youtube.com/embed/w8UlYBmw3io?si=y3Kd9b23EWyEhngB",
  },
  {
    videoURL: "https://www.youtube.com/embed/WEDppj4Msy4?si=WB7IwqXzPGe-KAdu",
  },
  {
    videoURL: "https://www.youtube.com/embed/_M77TrRyDwM?si=3nLv5I9R4ObF1yxy",
  },
  {
    videoURL: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fshadowinfosystemlimited%2Fvideos%2F179731614972916%2F&show_text=false&width=560&t=0",
  },
];

class ClientsLogo1 extends React.Component {
  render() {
    const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b10 bg-repeat bacg-ser">
          <div className="container m-b40">
            {/* TITLE START */}
            <div className="section-head">
              <div
                className={`${this.props.separatoralignment} sx-separator-outer `}
              >
                <div className="text-center">
                  <h3 className="wfgrbwe ert678 mb-3">Our Journey Turns Goals into <br/>Remarkable Achievements</h3>
                  <p>We transform aspirations into remarkable achievements. <br/>Every milestone reached reflects our dedication, determination, and the relentless pursuit of excellence</p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            
            <div className="section-content">
              <div className="client-grid">
                <div className="row justify-content-center">
                  <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {logos.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <iframe
                        className="video-frame"
                        title="Making your vision"
                        width="100%"
                        height={120}
                        src={item.videoURL}
                      />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
                </div>
              </div>
            </div>
            <button class="darksoul-hover-fill-button2 darksoul-hover-fill-button2" aria-label="View More" type="button">
              <div class="color-fill-2 new_one_animation5"></div>
              <a
                href="achievements"
                target="_blank"
                className=""
              >
                <span>
                  View More{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                    />
                  </svg>
                </span>
              </a>
            </button>
            
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
