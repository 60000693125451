import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import videoBg from "./../../../images/logisoft.mp4";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div className={`${this.props.bgcolor} section-full mobile-page-padding back-ground-color p-t80 p-b50`}  >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                <h1 className="ert678 text-center">Why choose the logisoft FLMS App?</h1>
                <p className="text-center">Our application provides you with the chance to experience:</p>
                  <div className="row ffeefefeeeecece">
                   <div className="wy-chooscs-useing">
                     <div className="list-data">
                       <img src={require('./../../../images/logicis/system-update.png')} width="60px" alt="" />  
                       <h6>Free updates</h6>
                     </div>
                     <div className="list-data">
                       <img src={require('./../../../images/logicis/customer-support.png')} width="60" alt="" />  
                       <h6>Free support</h6>
                     </div>
                     <div className="list-data">
                       <img src={require('./../../../images/logicis/label.png')} width="60" alt="" />  
                       <h6>White-labeled product</h6>
                     </div>
                     <div className="list-data">
                       <img src={require('./../../../images/logicis/optimization.png')} width="60" alt="" />  
                       <h6>Easily scalable</h6>
                     </div>
                     <div className="list-data">
                       <img src={require('./../../../images/logicis/downloading.png')} width="60" alt="" />  
                       <h6>Free installation</h6>
                     </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default About3;
