import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import videoBg from "./../../../images/logisoft.mp4";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 back-ground-color`}
        >
        
          <div className="container-fluid pd-0123">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row ffeefefeeeecece">
                   {/* <div className="col-xl-5 col-lg-5 col-md-5">
                    <img className="" src={require("./../../../images/102.jpg")} alt=""/>
                    </div>*/}
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="custom__video_section_with_video_bg">
         
                           <video className="video__bg" autoPlay loop muted>
                             <source src={videoBg} type="video/webm" />
                           </video>

                          <div className="video_bg_content_container"></div>
          
                       </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 ">
                    <div>
                      <h1 className="ert678">Logisoft Product Overview</h1>
                      <p>
                        Logitech is an easy to use online/offline ERP Platform for Freight Forwarding & Logistics Industry.it helps you to organize your Operations & Financial accounting in one platform. 
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </>
    );
  }
}

export default About3;
