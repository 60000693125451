import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/main-slider/slider5/new-slider-bg.webp");
var bgimg2 = require("./../../../images/background/cross-line2.png");
var bgimg3 = require("./../../../images/main-slider/slider5/Untitled-6.png");
var bgimg4 = require("./../../../images/update1/f2.webp");
var bgimg5 = require("./../../../images/update1/Banner.webp");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding dfbb7680978  p-b10 bg-repeat ertyuioiu1 ejbjcnjvf"
          style={{ backgroundImage: "url(" + bgimg3 + ")" }}
        >
         
          <div className="container">
            <div className="row">
              {/* Head Office */}

              <div className="col-md-9">
                <div className="section-one sfwd1324 rrtyui">
                  <div className="banner-content rtyuiopoiu">
                    <h1 className="f-h1">
                      Accelerate Your Business<br/>Technology That Drives Success
                    </h1>
                    <p>
                      Unlocking New Horizons with Next-Gen IT Solutions That Turn Technology Into Your Business’s Greatest Asset for Growth and Innovation
                    </p>

                    <div className="dndu108">
                    <a href="https://www.google.com/partners/agency?id=4328355085" target="_blank" className="google-patner">
                    <img src={require("./../../../images/Layer 0.png")} alt="Google Partners"/>
                    </a>
                      <img
                        src={require("./../../../images/background/Amazon-1-1.png") }
                        className="iuytre"
                        alt="AWS Services"
                      />
                      <img
                        src={require("./../../../images/update1/meta1.webp")}
                        className="iuytre"
                        alt="Meta Business Partner"
                      />

                      <img
                        src={require("./../../../images/govermnent of india logo.png")}
                        className="iuytre"
                        alt="GOI Projects"
                      />
                      <img
                        src={require("./../../../images/Layer.png")}
                        className="iuytre1"
                        alt="Certificate ISO 27001:2013"
                      />

                    </div>
                    {/* <div className="buttonfgerb">
                      <div className="header-section-12">
                        <NavLink
                          to={"/about-us"}
                          title="READ MORE"
                          rel="bookmark"
                          className="hs12-button"
                        >
                          Find Out More
                        </NavLink>
                      </div>
                    </div> */}
                    <button
                      class="darksoul-hover-fill-button2 mt-3 ml-3"
                      type="button" aria-label="find out more"
                    >
                      <div class="color-fill-2 new_one_animation"></div>
                      <NavLink to={"/about-us"} rel="bookmark" className="">
                        Find Out More{" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                    />
                  </svg>
                      </NavLink>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
