import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import FLMSAbout from "./../Elements/FreightLogisticsManagementSystem/FLMSAbout";
import FLMSBanner from "./../Elements/FreightLogisticsManagementSystem/FLMSBanner";
import FLMSServices from "./../Elements/FreightLogisticsManagementSystem/FLMSServices";
import FLMSMS from "./../Elements/FreightLogisticsManagementSystem/FLMSMS";
import FLMSWhyChooseUs from "./../Elements/FreightLogisticsManagementSystem/FLMSWhyChooseUs";
import FLMSBestServices from "./../Elements/FreightLogisticsManagementSystem/FLMSBestServices";
import FLMSListOption from "./../Elements/FreightLogisticsManagementSystem/FLMSListOption";
import FLMSOverview from "./../Elements/FreightLogisticsManagementSystem/FLMSOverview";
import FLMSOpration from "./../Elements/FreightLogisticsManagementSystem/FLMSOpration";
import Inqury from "./../Elements/FreightLogisticsManagementSystem/Inqury";

import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
       <Helmet>
          <title>Freight Logistics Management System</title>
          <meta name="description" content="Enhance your supply chain with our Freight Logistics Management System. Streamline operations, improve tracking, and boost efficiency with advanced logistics solutions." />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/freight-logistics-management-system" />
     </Helmet>
     <Header2 />
        <div className="page-content">
         <FLMSBanner/>
         <FLMSMS/>
         <FLMSServices/>
         <FLMSOpration/>
         <FLMSAbout/>
         <FLMSBestServices />
         <FLMSListOption />
         <Inqury />
         <FLMSOverview/>
       </div>
    <Footer2 />
      </>
    );
  }
}

export default Service1;
