import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/background/bg-12.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                  {/* <h5>Let's Code Your Success Story Together</h5> */}
                  <h2 className="ert678">
                  <h4>We’re happy to assist!</h4>
                   Need More Info? We're Here to Help!
                  </h2>
                  
                  <p>
                    Explore our range of high-quality products designed to meet your needs. Have questions? Contact us for more details
                  </p>
                </div>
                <div className="common-enq-bot">
                  <a
                    href="https://api.whatsapp.com/send?phone=+918285560008&text=Hello Shadow"
                    target="_blank"
                    className=""
                  >
                    <img
                      className="wdmwoncc019281"
                      src={require("./../../../images/icon/whatsapp-button (1).png")}
                      alt=""
                    />
                  </a>
                  <span>OR</span>
                  <a href="tel:+91-8800384880" target="_blank" className="">
                    <img
                      className="wdmwoncc019281"
                      src={require("./../../../images/icon/Call-Now-Button.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
