import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import videoBg from "./../../../images/logisoft.mp4";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div className={`${this.props.bgcolor} section-full mobile-page-padding back-ground-color`} >
          <div className="container-fluid bac-grwcsc">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row ffeefefeeeecece">
                   {/* <div className="col-xl-5 col-lg-5 col-md-5">
                    <img className="" src={require("./../../../images/102.jpg")} alt=""/>
                    </div>*/}
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <img src={require('./../../../images/logicis/25625376_7107884.jpg')} alt="" />  
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 ">
                    <div>
                      <h1 className="ert678">We Provided Best SaaS Products Services</h1>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="box">
                            <h6>Agile Methodology</h6>
                            <p>We adopt agile methodologies to deliver high-quality products efficiently.</p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="box">
                            <h6>Scalability and Performance</h6>
                            <p>We build SaaS products that can handle growing user loads and evolving business needs.</p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="box">
                            <h6>Customer-Centric Approach</h6>
                            <p>We prioritize your needs and tailor our solutions to your specific requirements</p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="box">
                            <h6>Expertise</h6>
                            <p>Our team has extensive experience in developing SaaS products across various industries.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </>
    );
  }
}

export default About3;
