import React from "react";
import Header2 from "./../Common/Header2";
import About from "./../Elements/Home/About";
import AboutNumber from "./../Elements/Home/AboutNumber";
import Services from "./../Elements/Home/Services";
import ProjectDiscuss from "./../Elements/Home/ProjectDiscuss";
import Customers from "./../Elements/Home/Customers";
import Industry from "./../Elements/Home/Industry";
import WebBanner from "./../Elements/Home/WebBanner";
import Achivment from "./../Elements/Home/Achivment";
import Testimonials from "./../Elements/Testimonials";
import CommenEnquiry from "./../Elements/CommenEnquiry";
// import Popup from "./../Elements/Home/Popup";
import Footer2 from "./../Common/Footer2";
import { Helmet } from "react-helmet";

class Home5 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best IT Company - Shadow infosystem</title>
          <meta
            name="description"
            content="Shadow infosystem is a leading IT company specializing in custom software development. Offering innovative solutions to enhance business efficiency and drive digital transformation."
          />
          <meta name="keywords" content="Best IT Company in Noida" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/" />
        </Helmet>
        <Header2 />
        <div className="page-content">
        {/*<Popup/>*/}
          <WebBanner />
          <About />
          <AboutNumber />
          <Services />
          <ProjectDiscuss />
          <Customers />
          <CommenEnquiry />
          <Industry />
          <Achivment />
          <Testimonials />
        </div>
        <Footer2 />
      </>
    );
  }
}

export default Home5;
