import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../ProductInquery";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
       <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45">
    <div className="container">
        <div className="section-content sfsfcsc13232">
            <div className="sx-separator-outer">
                <div className="text-center">
                    <h1 className="ert678">
                        What Makes Our <br />
                        Integrated Operations Stand Out
                    </h1>
                    <p>
                        Our integrated operations streamline processes, enhance efficiency, and drive seamless collaboration across all functions. By unifying technology, data, and workflows, we eliminate silos, reduce costs, and improve
                        decision-making. With a focus on innovation and reliability, we empower businesses to operate smarter, faster, and more effectively.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/setting.png')} alt="" />
                        </div>
                        <h4>Integrate Operations</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/productivity.png')} alt="" />
                        </div>
                        <h4>Increase Productivity</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/24-hours-support.png')} alt="" />
                        </div>
                        <h4>Better Customer Service</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/increase.png')} alt="" />
                        </div>
                        <h4>Increase Profitability</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/reduction.png')} alt="" />
                        </div>
                        <h4>Reduces costs</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table1">
                        <div className="alscmscs">
                            <img src={require('./../../../images/logicis/training-program.png')} alt="" />
                        </div>
                        <h4>Products Demo</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 offset-lg-4">
                  <TypeDesign />
                </div>
            </div>
        </div>
    </div>
</div>


      </>
    );
  }
}

export default Blog2;
