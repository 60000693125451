import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/13936.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser ertyuioiu-dfdfdf"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-5">
                  {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-left">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3 heascss">
                    Freight Management System
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7">
                  <div className="deals_in">
                    <ul className="deals_list">
                      <li><img src={require("./../../../images/flms/freight_18551383.png")} alt=""/>Road Transport Module</li>
                      <li><img src={require("./../../../images/flms/air-freight_5428412.png")} alt=""/>Freight & Forwarding</li>
                    </ul>
                    <ul className="deals_list">
                      <li><img src={require("./../../../images/flms/flatbed_18551267.png")} alt=""/>Fleet Management</li>
                      <li><img src={require("./../../../images/flms/notes_6690799.png")} alt=""/>Accounting Module</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
