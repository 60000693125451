import React from "react";
import { NavLink } from "react-router-dom";

import ImageGallery from "react-image-gallery";

const realstate = [
  {
    image: require("./../../../images/update1/dv6.webp"),
  },
];
const medical = [
  {
    image: require("./../../../images/update1/dv7.webp"),
  },
];
const education = [
  {
    image: require("./../../../images/update1/dv4.webp"),
  },
];

const commerce = [
  {
    image: require("./../../../images/update1/dv5.webp"),
  },
];

var bgimg1 = require("./../../../images/bacgarref.png");

class ShopDetail extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <div
          className="page-content fscs1213098 bacg-ser">
          {/* SHOP DETAIL SECTION START */}
          <div className="section-full p-t80 p-b50 mobile-page-padding">
            <div className=" woo-entry">
              {/* TABS CONTENT START */}
              <div className="product-full-detail m-b30">
                <div className="container">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="sx-separator-outer separator-center">
                      <div className="">
                        <h3 className="ert678">
                          Pioneering IT Company Excellence <br />
                          Through Innovative and Straight Forward Approaches
                        </h3>
                        <p>
                          As a prominent digital marketing company, we
                          prioritize simplicity in our approach. Through our
                          innovative and effective processes, we have solidified
                          our position as a reputable and trustworthy digital
                          marketing agency. At Shadow Infosystem, we specialize
                          in assisting small businesses in building their brands
                          from the ground up. Our clients find it easy to
                          address even the most intricate challenges, thanks to
                          our laser-focused work approach, which includes:
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  <div className="sx-tabs tabs-default acc123">
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="nav nav-tabs accour">
                          <li>
                            <a
                              data-toggle="tab"
                              href="#web-design-19"
                              className="active fd12543"
                            >
                              <img
                                src={require("./../../../images/checked.png")}
                                alt="team_shadow"
                              />
                              <div className="csjcnv123e">
                                <h4>Customer Satisfaction</h4>
                                <div>
                                  <p>
                                    We follow a customer-centric approach,
                                    ensuring that the final product meets our
                                    customer’s expectations.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#developement-20">
                              <img
                                src={require("./../../../images/checked.png")}
                                alt="team_shadow"
                              />
                              <div className="csjcnv123e">
                                <h4>Team Collaboration</h4>
                                <div>
                                  <p>
                                    We promote teamwork and collaboration,
                                    maintaining transparency with customers by
                                    keeping all communication channels open.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#developement-21">
                              <img
                                src={require("./../../../images/checked.png")}
                                alt="team_shadow"
                              />
                              <div className="csjcnv123e">
                                <h4>Delivering Results</h4>
                                <div>
                                  <p>
                                    We ensure to execute every measure that's
                                    required to help you reach your desired
                                    outcome.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#developement-22">
                              <img
                                src={require("./../../../images/checked.png")}
                                alt="team_shadow"
                              />
                              <div className="csjcnv123e">
                                <h4>Cost Efficiency</h4>
                                <div>
                                  <p>
                                    We focus on delivering value early and
                                    frequently, allowing businesses to
                                    prioritize features based on their value and
                                    return on investment.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 xvbf3242 p-0">
                        <div className="tab-content sfdvcs242233 swwddwd">
                          <div id="web-design-19" className="tab-pane active">
                            <div className="container">
                              {/* GOOGLE MAP & CONTACT FORM */}
                              <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                  {/* ABOUT COMPANY */} {/* USEFUL LINKS */}
                                  {realstate.map((item, index) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                                      <div className="">
                                        <NavLink to={item.URL}>
                                          <img
                                            src={item.image}
                                            alt="team_shadow"
                                          />
                                        </NavLink>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="developement-20" className="tab-pane">
                            <div className="container">
                              {/* GOOGLE MAP & CONTACT FORM */}
                              <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                  {/* ABOUT COMPANY */} {/* USEFUL LINKS */}{" "}
                                  {medical.map((item, index) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <div className="">
                                        <NavLink to={item.URL}>
                                          <img
                                            src={item.image}
                                            alt="team_shadow"
                                          />
                                        </NavLink>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="developement-21" className="tab-pane">
                            <div className="container">
                              {/* GOOGLE MAP & CONTACT FORM */}
                              <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                  {/* ABOUT COMPANY */} {/* USEFUL LINKS */}{" "}
                                  {education.map((item, index) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <div className="">
                                        <NavLink to={item.URL}>
                                          <img
                                            src={item.image}
                                            alt="team_shadow"
                                          />
                                        </NavLink>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="developement-22" className="tab-pane">
                            <div className="container">
                              {/* GOOGLE MAP & CONTACT FORM */}
                              <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                  {/* ABOUT COMPANY */} {/* USEFUL LINKS */}{" "}
                                  {commerce.map((item, index) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <div className="">
                                        <NavLink to={item.URL}>
                                          <img
                                            src={item.image}
                                            alt="team_shadow"
                                          />
                                        </NavLink>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* TABS CONTENT START */} {/* TITLE START */}
            </div>
          </div>
          {/* SHOP DETAIL SECTION END */}
        </div>
      </>
    );
  }
}

export default ShopDetail;
