import React from "react";
import { NavLink } from "react-router-dom";
import WhatsAppForm from "./../Elements/WhatsAppForm";
var bgimg1 = require("./../../images/background/cross-line2.png");

class Services1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logo: require("./../../images/logo-light.png") };
  }

  state = { isSearchActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  componentDidMount() {
    function privacyAlert388974000000262003() {
      var privacyTool = document.getElementById(
        "privacyTool388974000000262003"
      );
      var privacyErr = document.getElementById("privacyErr388974000000262003");
      if (privacyTool != undefined && !privacyTool.checked) {
        privacyErr.style.visibility = "visible";
        privacyTool.focus();
        return false;
      }
      return true;
    }
    function disableErr388974000000262003() {
      var privacyTool = document.getElementById(
        "privacyTool388974000000262003"
      );
      var privacyErr = document.getElementById("privacyErr388974000000262003");
      if (
        privacyTool != undefined &&
        privacyTool.checked &&
        privacyErr != undefined
      ) {
        privacyErr.style.visibility = "hidden";
      }
    }

    /* Do not remove this code. */
    function reloadImg388974000000262003() {
      var captcha = document.getElementById("imgid388974000000262003");
      if (captcha.src.indexOf("&d") !== -1) {
        captcha.src =
          captcha.src.substring(0, captcha.src.indexOf("&d")) +
          "&d" +
          new Date().getTime();
      } else {
        captcha.src = captcha.src + "&d" + new Date().getTime();
      }
    }

    function checkMandatory388974000000262003() {
      var mndFileds = new Array("Company", "Last Name", "Email", "Phone");
      var fldLangVal = new Array(
        "Company\x20Organization",
        "Your\x20Name",
        "Your\x20Email",
        "Your\x20Phone\x20No."
      );
      for (let i = 0; i < mndFileds.length; i++) {
        var fieldObj =
          document.forms["WebToLeads388974000000262003"][mndFileds[i]];
        if (fieldObj) {
          if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
            if (fieldObj.type == "file") {
              alert("Please select a file to upload.");
              fieldObj.focus();
              return false;
            }
            alert(fldLangVal[i] + " cannot be empty.");
            fieldObj.focus();
            return false;
          } else if (fieldObj.nodeName == "SELECT") {
            if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
              alert(fldLangVal[i] + " cannot be none.");
              fieldObj.focus();
              return false;
            }
          } else if (fieldObj.type == "checkbox") {
            if (fieldObj.checked == false) {
              alert("Please accept  " + fldLangVal[i]);
              fieldObj.focus();
              return false;
            }
          }
          try {
            if (fieldObj.name == "Last Name") {
              let name = fieldObj.value;
            }
          } catch (e) {}
        }
      }
      if (!privacyAlert388974000000262003()) {
        return false;
      }

      var urlparams = new URLSearchParams(window.location.search);
      if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
        var webform = document.getElementById("webform");
        var service = urlparams.get("service");
        var smarturlfield = document.createElement("input");
        smarturlfield.setAttribute("type", "hidden");
        smarturlfield.setAttribute("value", service);
        smarturlfield.setAttribute("name", "service");
        webform.appendChild(smarturlfield);
      }
      document
        .querySelector(".crmWebToEntityForm .formsubmit")
        .setAttribute("disabled", true);
    }

    function tooltipShow388974000000262003(el) {
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if (tooltipDisplay == "none") {
        var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
        for (let i = 0; i < allTooltip.length; i++) {
          allTooltip[i].style.display = "none";
        }
        tooltip.style.display = "block";
      } else {
        tooltip.style.display = "none";
      }
    }

    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const isSearchActive = this.state.isSearchActive;

    return (
      <>
        <div className=" new-popup-button">
          <div className="extra-cell common-enq-bot sdfghgfd3456">
            {/* <NavLink
              to={"#"}
              className="nrew-popop-buttonqwe bottt1"
              onClick={this.handleSearchToggle}
            >
              Start a Project
            </NavLink> */}
            <button class="darksoul-hover-fill-button2 ml-2" type="button">
              <div class="color-fill-2 new_one_animation"></div>
              <NavLink to={"#"} className="" onClick={this.handleSearchToggle}>
                Start a Project{" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                    />
                  </svg>
              </NavLink>
            </button>
          </div>
        </div>
        {/* EXTRA Nav */}
        {/* MAIN NAVIGATION */}
        <div className="header-nav nav-dark navbar-collapse collapse justify-content-center collapse"></div>
        {/* SITE SEARCH */}
        <div id="search" className={isSearchActive ? "open" : null}>
          <div className="cjcncc935837">
            <div className="contact-nav">
              <div className="contact-nav-form">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 mvgee">
                    <div className="5uigfh ssvdvnlpo">
                      <h4 className="ert678 fef098765">
                        Business Questions? Meet Our Expert
                      </h4>
                      <p className="cnjc0887">
                        Be it a service you need, a service you seek, or if you
                        like what you have seen so far; do not hesitate to reach
                        out. We're excited to start a conversation.
                      </p>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs inline-icon m-b20 scale-in-center">
                          <img
                            src={require("./../../images/flag-50.png")}
                            className="county-logo"
                            alt="Inteshape"
                          />
                        </div>
                        <div className="icon-content">
                          <h6 className="m-t0">India </h6>
                          <p>
                            <a href="tel:+91-8285560008">+91-8285560008 </a>
                          </p>

                          <p>
                            <a href="tel:+91-8800384880">+91-8800384880</a>
                          </p>
                         
                        </div>
                      </div>
                      

                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs inline-icon m-b20 scale-in-center">
                          <img
                            src={require("./../../images/usa-50.png")}
                            className="county-logo"
                            alt="Inteshape"
                          />
                        </div>
                        <div className="icon-content">
                          <h6 className="m-t0">USA </h6>
                          <p>
                            <a href="tel:(+1)-720-384-8706" target="_blank">
                              {" "}
                              (+1)-720-384-8706
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs inline-icon m-b20 scale-in-center">
                          <img
                            src={require("./../../images/canada-1.png")}
                            className="county-logo"
                            alt="Inteshape"
                          />
                        </div>
                        <div className="icon-content">
                          <h6 className="m-t0">Canada </h6>
                          <p>
                            <a href="mailto:sales@shadowinfosystem.com">
                              {" "}
                              sales@shadowinfosystem.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs inline-icon m-b20 scale-in-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="white"
                              d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 0 1-76.8 0L0 176z"
                            />
                          </svg>
                        </div>
                        <div className="icon-content">
                          <h6 className="m-t0">Mail: </h6>
                          <p>
                            <a href="mailto:info@shadowinfosystem.com">
                              {" "}
                              info@shadowinfosystem.com
                            </a>
                          </p>
                          <p>
                            <a href="mailto:sales@shadowinfosystem.com">
                              {" "}
                              sales@shadowinfosystem.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="full-social-bg">
                        <h6 className="m-t0">Follow US: </h6>
                        <ul className="ertyupoi">
                          <li>
                            <a
                              href="https://www.facebook.com/shadowinfosystemlimited"
                              className="facebook"
                              target="_blank"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/shadowinfosystem/"
                              className="instagram"
                              target="_blank"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/shadow-infosystem/"
                              className="tumblr"
                              target="_blank"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/i/flow/login?redirect_after_login=%2Fshadowinfosyste"
                              className="twitter"
                              target="_blank"
                            >
                              <img
                                src={require("./../../images/twitter (1).png")}
                                className="county-logo1"
                                alt="Shadow infosystem"
                              />
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.youtube.com/@Shadowinfosystem"
                              target="_blank"
                              className="youtube"
                            >
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 ddsd">
                    <div className="popup-dsafs">
                      <div className="contact-nav-field bg-white">
                        <NavLink
                          to={"#"}
                          className="contact_close"
                          onClick={this.handleSearchToggle}
                        >
                          {/*<span className="close" onClick={this.handleSearchToggle} />*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7s-7-3.1-7-7s3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8z"
                            />
                            <path
                              fill="currentColor"
                              d="M12.2 10.8L9.4 8l2.8-2.8l-1.4-1.4L8 6.6L5.2 3.8L3.8 5.2L6.6 8l-2.8 2.8l1.4 1.4L8 9.4l2.8 2.8z"
                            />
                          </svg>
                        </NavLink>

                        <div className="wevfw47464">
                          <div
                            id="crmWebToEntityForm"
                            className="zcwf_lblTopBottom crmWebToEntityForm dsdwd123121"
                          >
                           <WhatsAppForm/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services1;
