import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import videoBg from "./../../../images/santosh_academia/santosh.webm";

const testimonials = [
  {
    image: require("./../../../images/myachieve/1.webp"),
  },
  {
    image: require("./../../../images/myachieve/2.webp"),
  },
  {
    image: require("./../../../images/myachieve/3.webp"),
  },
  {
    image: require("./../../../images/myachieve/4.webp"),
  },
  {
    image: require("./../../../images/myachieve/5.webp"),
  },
  {
    image: require("./../../../images/myachieve/6.webp"),
  },
];
const testimonials1 = [
  {
    image: require("./../../../images/myachieve/7.webp"),
  },
  {
    image: require("./../../../images/myachieve/8.webp"),
  },
  {
    image: require("./../../../images/myachieve/9.webp"),
  },
  {
    image: require("./../../../images/myachieve/10.webp"),
  },
];
const testimonials2 = [
  {
    image: require("./../../../images/myachieve/m1.webp"),
  },
  {
    image: require("./../../../images/myachieve/m2.webp"),
  },
  {
    image: require("./../../../images/myachieve/m3.webp"),
  },
  {
    image: require("./../../../images/myachieve/m4.webp"),
  },
  {
    image: require("./../../../images/myachieve/m5.webp"),
  },
];
const testimonials3 = [
  {
    image: require("./../../../images/myachieve/imr1.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr2.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr3.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr4.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr5.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr6.webp"),
  },
  {
    image: require("./../../../images/myachieve/imr7.webp"),
  },
];
const testimonials4 = [
  {
    image: require("./../../../images/myachieve/cnj1.webp"),
  },
  {
    image: require("./../../../images/myachieve/cnj2.webp"),
  },
  {
    image: require("./../../../images/myachieve/cnj3.webp"),
  },
  {
    image: require("./../../../images/myachieve/cnj4.webp"),
  },
];
const testimonials5 = [
  {
    image: require("./../../../images/myachieve/dex1.webp"),
  },
  {
    image: require("./../../../images/myachieve/dex2.webp"),
  },
  {
    image: require("./../../../images/myachieve/dex3.webp"),
  },
  {
    image: require("./../../../images/myachieve/dex4.webp"),
  },
  {
    image: require("./../../../images/myachieve/dex5.webp"),
  },
];
const testimonials6 = [
  {
    image: require("./../../../images/myachieve/dipm1.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm2.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm3.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm4.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm5.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm6.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm7.webp"),
  },
  {
    image: require("./../../../images/myachieve/dipm8.webp"),
  },
];
const testimonials7 = [
  {
    image: require("./../../../images/myachieve/ict1.webp"),
  },
  {
    image: require("./../../../images/myachieve/ict2.webp"),
  },
  {
    image: require("./../../../images/myachieve/ict3.webp"),
  },
  {
    image: require("./../../../images/myachieve/ict4.webp"),
  },
];
const testimonials8 = [
  {
    image: require("./../../../images/myachieve/vital1.webp"),
  },
  {
    image: require("./../../../images/myachieve/vital2.webp"),
  },
  {
    image: require("./../../../images/myachieve/vital3.webp"),
  },
];

const testimonials9 = [
  {
    image: require("./../../../images/gctc1/1.webp"),
  },
  {
    image: require("./../../../images/gctc1/2.webp"),
  },
  {
    image: require("./../../../images/gctc1/3.webp"),
  },
  {
    image: require("./../../../images/gctc1/4.webp"),
  },
  {
    image: require("./../../../images/gctc1/5.webp"),
  },
  {
    image: require("./../../../images/gctc1/6.webp"),
  },
  {
    image: require("./../../../images/gctc1/7.webp"),
  },
  {
    image: require("./../../../images/gctc1/8.webp"),
  },
  {
    image: require("./../../../images/gctc1/9.webp"),
  },
  {
    image: require("./../../../images/gctc1/10.webp"),
  },
];
const testimonials10 = [
  {
    image: require("./../../../images/gctc2/1.webp"),
  },
  {
    image: require("./../../../images/gctc2/3.webp"),
  },
  {
    image: require("./../../../images/gctc2/4.webp"),
  },

  {
    image: require("./../../../images/gctc2/6.webp"),
  },
  {
    image: require("./../../../images/gctc2/7.webp"),
  },
  {
    image: require("./../../../images/gctc2/8.webp"),
  },
  {
    image: require("./../../../images/gctc2/9.webp"),
  },
  {
    image: require("./../../../images/gctc2/10.webp"),
  },
  {
    image: require("./../../../images/gctc2/11.webp"),
  },
];
const testimonials11 = [
  {
    image: require("./../../../images/gctc3/1.webp"),
  },
  {
    image: require("./../../../images/gctc3/2.webp"),
  },
  {
    image: require("./../../../images/gctc3/5.webp"),
  },
  {
    image: require("./../../../images/gctc3/7.webp"),
  },
  {
    image: require("./../../../images/gctc3/8.webp"),
  },
  {
    image: require("./../../../images/gctc3/9.webp"),
  },
,
];
const testimonials12 = [
  {
    image: require("./../../../images/gctc4/1.webp"),
  },
  {
    image: require("./../../../images/gctc4/2.webp"),
  },
  {
    image: require("./../../../images/gctc4/4.webp"),
  },
];
const testimonials13 = [
  {
    image: require("./../../../images/gctc5/1.webp"),
  },
  {
    image: require("./../../../images/gctc5/2.webp"),
  },
  {
    image: require("./../../../images/gctc5/3.webp"),
  },
  {
    image: require("./../../../images/gctc5/4.webp"),
  },
  {
    image: require("./../../../images/gctc5/5.webp"),
  },
  {
    image: require("./../../../images/gctc5/6.webp"),
  },
];
const testimonials14 = [
  {
    image: require("./../../../images/brics_jumm/4.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/2.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/1.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/3.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/5.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/6.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/7.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/8.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/9.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/10.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/11.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/12.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/13.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/14.jpg"),
  },
  {
    image: require("./../../../images/brics_jumm/15.jpg"),
  },
];
const testimonials15 = [
  {
    image: require("./../../../images/brics_b/1.jpg"),
  },
  {
    image: require("./../../../images/brics_b/2.jpg"),
  },
  {
    image: require("./../../../images/brics_b/3.jpg"),
  },
  {
    image: require("./../../../images/brics_b/4.jpg"),
  },
  {
    image: require("./../../../images/brics_b/5.jpg"),
  },
  {
    image: require("./../../../images/brics_b/6.jpg"),
  },
  {
    image: require("./../../../images/brics_b/7.jpeg"),
  },
  {
    image: require("./../../../images/brics_b/8.jpeg"),
  },
  {
    image: require("./../../../images/brics_b/9.jpg"),
  },
  {
    image: require("./../../../images/brics_b/10.jpg"),
  },
];

var bgimg1 = require("./../../../images/background/bg6.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class NewAchi extends React.Component {
  render() {
    const options = {
      loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 4,
        },
        1350: {
          items: 4,
        },
      },
    };
    return (
      <>
      <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">BRICS Youth Council Entrepreneurship Pre-Consultations</h2>
                      <h4>Deep Tech for Tomorrow<br/>Youth-Led Innovations Driving India's Leadership in Emerging Technologies</h4>
                      <span>19 Feb 2025, IISc, Bangalore</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>Team Shadow infosystem is elated to have played a pivotal role in providing IT Support and Services for the prestigious BRICS Youth Council Event at the Indian Institute of Science (IISc), Bengaluru! </p>
                  <p>The event, centered around the theme "DEEPTECH FOR TOMORROW" was a convergence of brilliant minds, young leaders, and innovators from across the BRICS nations, aimed at shaping the future of technology and entrepreneurship.</p>
                  <p>A special moment for our Founder & CEO, Kumar Rajesh, who facilitated the esteemed guests and showcased our commitment to innovation and excellence. </p>
                  <p>This milestone is a testament to our dedication to excellence and our passion for empowering impactful initiatives. </p>
                  <p>We are grateful for the trust placed in us by the Global Counter-Terrorism Council (GCTC) and the Ministry of Youth Affairs and Sports to ensure the event's seamless execution.</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/ERaS2i1KU-U?si=-pQsFMj4nOfBg7Qs"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials15.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">BRICS Youth Council Entrepreneurship Pre-Consultations</h2>
                      <h4>Empowering Young Innovators Across Brics Nations</h4>
                      <span>24 Jan 2025, IIM, Jammu</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>Youth entrepreneurship is a key driver for development not just in India but across the globe. Countries from both the Global North and Global South are leveraging their vast demographic dividends to tackle the pressing challenges of the 21st century.</p>
                  <p>This vibrant, day-long event offers a dynamic platform for innovators, aspiring entrepreneurs, and youth leaders to present groundbreaking business ideas and entrepreneurial initiatives.</p>
                  <p>Team Shadow Infosystem is honored to be the Trusted IT Partner for this prestigious event series held and upcoming events across India, providing seamless and innovative technology solutions to power the entrepreneurial journey.</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/8Ld0UbBMY_8?si=uXDlri0cE_NgBtYV"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials14.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">EUROPEAN UNION-INDIA Regional Track 1.5 Conference</h2>
                      <span>21-22 August 2024</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    The Track 1.5 Conference is designed to tackle the complex
                    challenges posed by violent extremism in the digital
                    landscape. This conference will focus on addressing the
                    various dimensions of online terrorist content and
                    developing effective strategies to counter its spread.
                  </p>
                  <p>
                    The event will feature key contributions from the Global
                    Counter Terrorism Council, as well as representatives from
                    several Indian ministries, including the Ministry of
                    External Affairs, Ministry of Power, Ministry of Defence,
                    and Ministry of Jal Shakti. Additionally, Shadow Infosystem
                    (P) Limited and a range of other influential participants
                    will play crucial roles in the discussions.
                  </p>
                  <p>
                    Set to take place at the prestigious Le Meridian in New
                    Delhi, this conference will bring together a diverse group
                    of experts, policymakers, and stakeholders dedicated to
                    creating a safer online environment and combating the menace
                    of online extremism.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/L4U6JrXcIiM?si=pOWVOfVxFfrhd0E3"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials11.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">Inspirational Speech by Honorable Vice President Shri
                      Jagdeep Dhankhar at GCTC 3rd Cyber Security Conference</h2>
                      <span>12-13 July 2024</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    We had the privilege of hearing the inspiring and
                    thought-provoking speech of Honorable Vice President Shri
                    Jagdeep Dhankhar at the GCTC - 3rd Cyber Security
                    Conference, sponsored by Shadow infosystem and held at
                    Vigyan Bhavan. We are grateful for the opportunity to
                    support this significant event and engage with a true leader
                    in the field.
                  </p>
                  <p>
                    His speech focused on the critical aspects and challenges of
                    cyber security, offering new directions and perspectives to
                    all attendees. The Vice President emphasized the importance
                    of innovation and technological advancement in cyber
                    security, with the aim of strengthening the nation's
                    security framework.
                  </p>
                  <p>
                    We take pride in participating in this conference and remain
                    committed to addressing such important issues in the
                    future.We are thankful for the guidance and inspiration of
                    the Honorable Vice President and hope to contribute to the
                    nation’s security and prosperity through such events.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/w8UlYBmw3io?si=y3Kd9b23EWyEhngB"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials10.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">Shadow infosystem Sponsored the 3rd GCTC Cyber Security
                      Conference</h2>
                      <span>12-13 July 2024</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Shadow Infosystem was proud to sponsor the 3rd Cyber
                    Security Conference, which took place on July 12-13 in New
                    Delhi under the esteemed patronage of the Ministry of
                    Electronics & Information Technology, Government of India.
                    This premier event was instrumental in driving forward the
                    Digital Bharat initiative by placing a strong emphasis on
                    enhancing cyber resilience.
                  </p>
                  <p>
                    The conference was a significant milestone in promoting a
                    secure and robust digital infrastructure, crucial for the
                    continued economic advancement of India. It brought together
                    leading experts, industry professionals, and policymakers to
                    discuss and address the evolving challenges in the
                    cybersecurity landscape. Our CEO had the distinct honor of
                    presenting awards to outstanding contributors who have made
                    remarkable strides in the field of cybersecurity.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/WEDppj4Msy4?si=5duvBqlBckgJs2Oh"
                      title="3rd GCTC Cyber Security Conference"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials9.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">National Conference on Women in Viksit Bharat</h2>
                      <span>22 March 2024</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Embrace the future of empowerment with us! Experience the
                    dynamic fusion of innovative ideas and actionable strategies
                    at the National Conference on Women in Viksit Bharat 2047:
                    Future Outlook. Here, Shadow Infosystem proudly positions
                    itself as a leading force in support and innovation. Join us
                    as we collaboratively forge a future where the voices of
                    women are amplified, resonating with strength and boundless
                    potential. Together, we are shaping a tomorrow filled with
                    opportunity and impact for every woman.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/_M77TrRyDwM?si=RhO3-h7K7gj4iKt2"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials12.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">National Conference on Climate Change & the Himalayas</h2>
                      <span>15 December 2023</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    It was a moment of immense pride for Team Shadow as we
                    launched the website of the Global Counter Terrorism Council
                    after months of hard work and dedication. The website,
                    www.gctcworld.org, was inaugurated by Shri Suresh Prabhu, a
                    former Union Minister for Civil Aviation, Railways, Commerce
                    & Industry, Chemicals & Fertilizers, Environment & Forests &
                    Power, Govt. of India and a former G20 & G7 Sherpa. Lt. Gen
                    KJ Singh, PVSM, AVSM & Bar (Retd), a former GOC-in-C,
                    Western Army Command, Indian Army, was also present at the
                    launch event. The Global Counter Terrorism Council organized
                    its 2nd CYBER SECURITY Conference, where Dr. Shekhar Dutt,
                    SM, IAS (Retd), a former Governor-Chhattisgarh, former
                    Defence Secretary, and former Deputy National Security
                    Advisor, Govt. of India, delivered the keynote address.
                  </p>
                </div>
              </div>

              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials13.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">Global Counter Terrorism Council (Website Launch)</h2>
                      <span>26-28 April 2023</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="m-b30 mt-3">
                  <p>
                    It was a moment of immense pride for Team Shadow as we
                    launched the website of the Global Counter Terrorism Council
                    after months of hard work and dedication. The website,
                    www.gctcworld.org, was inaugurated by Shri Suresh Prabhu, a
                    former Union Minister for Civil Aviation, Railways, Commerce
                    & Industry, Chemicals & Fertilizers, Environment & Forests &
                    Power, Govt. of India and a former G20 & G7 Sherpa. Lt. Gen
                    KJ Singh, PVSM, AVSM & Bar (Retd), a former GOC-in-C,
                    Western Army Command, Indian Army, was also present at the
                    launch event. The Global Counter Terrorism Council organized
                    its 2nd CYBER SECURITY Conference, where Dr. Shekhar Dutt,
                    SM, IAS (Retd), a former Governor-Chhattisgarh, former
                    Defence Secretary, and former Deputy National Security
                    Advisor, Govt. of India, delivered the keynote address.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fshadowinfosystemlimited%2Fvideos%2F179731614972916%2F&show_text=false&width=560&t=0" 
                    width="100%" height="300"
                    scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                    allowFullScreen="true"></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">DIPM Council of India App Launching , IT Partner Shadow</h2>
                      <span>01 Feb 2019</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="m-b30 mt-3">
                  <p>
                    The mobile app for the DIPM Council of India, developed by
                    Team Shadow Infosystem Pvt. Ltd., was launched in a ceremony
                    graced by Dr. VK Saraswat, Member of NITI Aayog and Chief
                    Patron of the DIPM Council of India. Also present were Lt.
                    Gen. SK Srivastava, AVSM, Engineer-in-Chief, Shri Arvind
                    Kumar Arora, DG, and Shri Pradeep Agarwal, IDSE, President
                    and CEO.
                  </p>
                  <p>
                    Shadow Infosystem Pvt. Ltd. played a pivotal role as the IT
                    partner for the International Conference & Exhibition
                    associated with the launch. This collaboration highlights
                    Shadow Infosystem's commitment to leveraging technology to
                    enhance the digital presence and operational efficiency of
                    institutions like the DIPM Council of India.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="m-b30 mt-3">
                  <div className="">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/1jN_OSWTEUE?si=u7-jiQACYwfBw5Cv"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials6.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">CENJOWS IOS APP LAUNCHING- DRDO BHAWAN (MINISTRY OF
                      DEFENCE)</h2>
                      <span>27-28 June 2018</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Today is the greatest day for Team Shadow to Launching
                    @CENJOWS iOS App at DRDO Bhawan, Ministry of Defence.
                    Heartwarming thanks to Lt Gen Satish Dua, PVSM, UYSM, SM,
                    VSM,CISC. Lt Gen Vinod Bhatia, PVSM, AVSM, VSM, Director
                    CENJOWS, Col YS Pathania and special thanks to Brig Manjeet
                    Singh, DACIDS (DIARA), HQ IDS for given a huge platform to
                    introduce it. CENJOWS is the First Think Tank in INDIA who
                    have iOS and Android Apps. Its abreast of Defence, Aerospace
                    Maritime, Strategic and Jointwarfare Domains.
                  </p>
                  {/* <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://cenjows.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials1.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">Defexpo 2018</h2>
                      <span>11 April 2018</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Prime Minister Modi inaugurated the DefExpo 2018 in Chennai,
                    emphasizing India's growing capabilities in defense
                    manufacturing and its commitment to modernizing its armed
                    forces. The event showcased India's defense prowess and
                    served as a platform for promoting defense exports and
                    collaborations with international partners.
                  </p>
                  <p>
                    At the event, Shadow Infosystem highlighted its expertise in
                    developing mission-critical software, cybersecurity
                    solutions, and advanced communication systems tailored for
                    defense applications. Their booth attracted attention with
                    demonstrations of robust command and control systems,
                    real-time data analytics platforms, and secure communication
                    networks designed to enhance operational efficiency and
                    situational awareness for defense forces.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials2.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">CENJOWS App & Website Launch</h2>
                      <span>24 Feb 2018</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Team Shadow celebrates a significant milestone! Lieutenant
                    General Vinod Bhatia, PVSM, AVSM, SM, Director of CENJOWS
                    and former DGMO (Director General of Military Operations),
                    along with our CEO, Mr. Kumar Rajesh, have launched the
                    mobile app and website www.cenjows.in for the Centre for
                    Joint Warfare Studies. This initiative marks a pivotal
                    moment in enhancing accessibility and digital outreach for
                    CENJOWS, empowering stakeholders with streamlined access to
                    crucial resources and information on joint warfare
                    strategies.
                  </p>
                  <p>
                    The Android app and website, meticulously crafted by Shadow
                    Infosystem Pvt. Ltd., reflect our commitment to delivering
                    robust technological solutions tailored to the specialized
                    needs of defense and strategic studies. By leveraging
                    cutting-edge development practices, we ensure seamless
                    functionality and user-friendly interfaces that cater to the
                    diverse requirements of military professionals, researchers,
                    and policy makers alike.
                  </p>
                  <p>
                    This accomplishment underscores Team Shadow's dedication to
                    supporting institutions like CENJOWS in their mission to
                    advance knowledge and expertise in joint warfare studies. As
                    we continue to innovate and evolve, we remain steadfast in
                    our pursuit of excellence, driving forward impactful
                    solutions that contribute to the defense and security
                    landscape.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials4.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">VITAL RADIOLOGY (CM UTTARAKHAND)</h2>
                      <span>10 Nov 2017</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    VitalRadiology services have been launched, introducing
                    Teleradiology services in Pauri, Uttarakhand. The
                    inauguration ceremony was honored by the presence of
                    Uttarakhand's Chief Minister, #ShriTrivendraSinghRawat. This
                    initiative aims to enhance healthcare services across the
                    state, particularly in remote hilly regions, ensuring timely
                    diagnosis and treatment for patients.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials8.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">IC-Tram by Vice President</h2>
                      <span>06 Oct 2017</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Over the last two decades, Indian Railways has experienced
                    exponential growth, expanding not only in size and capacity
                    but also in its adoption of state-of-the-art technologies.
                    Presently, Indian Railways is venturing into pioneering
                    domains such as High-Speed Railways and Dedicated Freight
                    Corridors. It is pushing boundaries by constructing railway
                    tracks in challenging terrains of the North-East, developing
                    mountain railways, and exploring alternative fuels to
                    decrease reliance on fossil fuels. These initiatives
                    showcase Indian Railways' commitment to innovation and
                    sustainable development in the transport sector.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials7.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">INDIAN MILITARY REVIEW App</h2>
                      <span>14 Aug 2016</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Shadow Infosystem marked a significant milestone by
                    launching an Android App in collaboration with Gen Ravi
                    Arora from Indian Military Review and Mr. Kumar Rajesh, CEO.
                    This app is dedicated to delivering defense news concerning
                    the Indian Army, Navy, Air Force, and other defense-related
                    topics. Developed and maintained by Shadow Infosystem's
                    Android Team, the app aims to provide timely updates and
                    insightful analyses to defense enthusiasts and stakeholders.
                  </p>
                  <p>
                    Shadow Infosystem's commitment to innovation and quality is
                    evident in this initiative, reinforcing its role as a leader
                    in leveraging technology for defense-related applications.
                    The launch underscores their dedication to supporting the
                    defense sector with cutting-edge digital solutions.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials3.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="mobile-page-padding h2tage ptb_40 bg-repeat bottom-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div class="nine">
                    <div className="myachieve">
                      <h2 class="ert678">Defexpo 2016</h2>
                      <span>28 March 2016</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    DefExpo 2016 Goa was inaugurated by the Defence Minister,
                    Shri Manohar Parrikar, on 28th March 2016. The event, held
                    in Goa, served as a prestigious platform for showcasing
                    cutting-edge defense technologies and fostering global
                    partnerships in the defense sector.
                  </p>
                  <p>
                    Among the distinguished participants was Shadow Infosystem
                    Pvt. Ltd., invited as a guest and IT partner. This
                    recognition underscored Shadow Infosystem's role in
                    contributing advanced technological solutions to support
                    defense initiatives and enhance capabilities in critical
                    areas such as cybersecurity, defense electronics, and
                    strategic IT infrastructure.
                  </p>
                  <p>
                    The inauguration marked a significant milestone in India's
                    defense landscape, emphasizing the country's commitment to
                    strengthening indigenous defense manufacturing and
                    innovation. DefExpo 2016 Goa not only facilitated the
                    exchange of knowledge and expertise but also paved the way
                    for collaborative efforts aimed at bolstering national
                    security and defense preparedness.
                  </p>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                {...options}
              >
                {testimonials5.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="hover-animation-1 bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center dfgh34235">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NewAchi;
