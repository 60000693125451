import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        
        <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color vdfbdvleft`}>
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                  <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              E-Commerce SEO Services to Improve Your Search Rankings
                            </h1>
                            <p>The most remarkable technique available in the field of e-commerce is search engine optimization commonly known as SEO. If you are looking for a better SERP rank and increased visibility, our e-commerce SEO services can help you achieve this.</p>
                            <p>Since we are an e-commerce SEO agency, we aim to utilize both on-page and off-page SEO strategies to improve your store visibility. On-page SEO involves the optimization of products, site speed, mobile friendliness, and the content on your site. Off-page SEO is all about procuring quality backlinks that raise the domain authority of the website.</p>
                            <p>Thus, following the optimal strategies of SEO, the Organic traffic to the e-commerce store and the search engine rankings will be improved. This results in more prospects being made aware of the products that you are selling thereby increasing the chances of sales and hence the revenue.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                         <img src={require("./../../../images/11637990_4788942.jpg")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color vdfbdv`}>
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                  
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img src={require("./../../../images/12079901_4912625.jpg")} alt=""/>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              Why E-Commerce SEO Is Critical for Your Business
                            </h1>
                            <p>It is important to note that SEO is a long-term strategy and when implemented appropriately, it yields long-term results. When you hire e-commerce SEO services, you are making sure that your online store is always ranking higher in the search engine, thus the visibility of your products to your customers increases.</p>
                            <p>This strategic approach is well incorporated into our company which deals with e-commerce SEO services to ensure proper client needs are met by actualizing the best practices that are synonymous with user experience, keyword optimization, and content relevance. This would not only increase the traffic to your store but also better quality traffic which is more likely to convert to sales.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
