import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        Earning the Trust of 1500+ Clients
                      </h3>
                      <p>Since 2008, we have been empowering businesses with cutting-edge Gen AI, Cyber Security, Web Development, and Performance Marketing solutions. With a commitment to innovation and security, we help organizations stay ahead in the digital era by delivering scalable, AI-driven, and result-oriented IT services.</p>
                    </div>
                  </div>

                  <MoreInform />
                </div>
              </div>
              <div className="col-md-5">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/head-icon/rhrgrrrrrrr.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
